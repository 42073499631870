import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../services/auth/auth.service';
import { ApiService } from '../../services/api/api.service';
import { ModalService } from '../../services/modal/modal.service';
import { AddUrlModalComponent } from '../attach-files/add-url-modal/add-url-modal.component';
import { AddFilesModalComponent } from '../attach-files/add-files-modal/add-files-modal.component';
import { BsModalRef } from 'ngx-bootstrap/modal';
import mimeDb from 'src/app/shared/components/attach-files/mime-db.json';
@Component({
  selector: 'app-attachment-options-modal',
  templateUrl: './attachment-options-modal.component.html',
  styleUrls: ['./attachment-options-modal.component.scss']
})
export class AttachmentOptionsModalComponent implements OnInit {

  addAttachments: any;
  attachments: any[];
  bsModalRef: BsModalRef;
  accept: string[] = ['doc','docx','pdf','png','xls','xlsx','ppt','pptx','jpeg','jpg'];
  allowedMimeTypes: string[] = [];

  constructor(
    private _auth: AuthService,
    private _api: ApiService,
    private _modal: ModalService,
    private _bsModalRef: BsModalRef
  ) { }

  ngOnInit(): void {
    this.defineMimeTypes();
  }

  openAttachmentModal(linkOrAttachment: string) {
    let categories;
    if (linkOrAttachment === 'resume') {
      categories = [
        {name: 'Primary'},
        {name: 'Formatted with Logo'},
        {name: 'Formatted with no Logo'},
        {name: 'Other'}
      ];
    } else {
      categories = [
        {name: 'Application'},
        {name: 'Bill Rate Confirmation'},
        {name: 'Certificate'},
        {name: 'Contract'},
        {name: 'Job Description'},
        {name: 'Reference'},
        {name: 'Resume'},
        {name: 'Right to Represent'},
        {name: 'Test Results'},
        {name: 'Portfolio/Work samples'},
        {name: 'Other'}
      ]
    }
    if (linkOrAttachment === 'link') {
      const initialState: any = {
        save: this.addAttachments,
        categories: categories,
        attachments: this.attachments,
        additionalParameters: {otherFile: true, talentDetailsPane: true},
        allowDuplicate: false
      };

      this.bsModalRef = this._modal.show(AddUrlModalComponent, { initialState });
      this.bsModalRef.setClass('modal-sm');
    } else {
      const chromeOS = /(CrOS)/.test(navigator.userAgent);

      const initialState: any = {
        save: this.addAttachments,
        categories: categories,
        WindowClass: 'modal-attachment',
        attachments: this.attachments,
        additionalParameters: {otherFile: true, talentDetailsPane: true},
        // JDS - removed file type validation temporarily to unblock chromebook users
        // Bypassing the file types validation for chromebook users
        allowedMimeTypes:  chromeOS ? [] : this.allowedMimeTypes,
        allowDuplicate: false,
        uploadURL: this._api.uploadURL(),
        parseResume: linkOrAttachment === 'resume' ? true : false,
        isTalentCreation: linkOrAttachment === 'resume' ? true : false
      };
      this._modal.show(AddFilesModalComponent, { class: 'modal-attachment', initialState  });
    }
  }

  closeModal() {
    this._bsModalRef.hide()
  }

  defineMimeTypes () {
    for (const mimeKey in mimeDb) {
      const mime: any = (<any>mimeDb)[mimeKey];
      if (mime.hasOwnProperty('extensions')) {
        const common = this.accept.some((x: any) => mime.extensions.includes(x));
        if (common) {
          this.allowedMimeTypes.push(mimeKey);
        }
        // console.log(common);
      }
    }
  }
}
