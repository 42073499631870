import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Observable, throwError, EMPTY } from 'rxjs';
import { catchError, mergeMap, tap } from 'rxjs/operators';
import { environment } from '../environments/environment';
import { AuthService } from '@auth0/auth0-angular';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

const withCredentials = environment.env !== 'dev' && environment.env !== 'local';
@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  email: string;
  avatar: string;
  googleId: string;

  constructor(private auth: AuthService, private router: Router) {}

  //@ts-ignore
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<void | HttpEvent<any>> {

    if(!this.auth.isAuthenticated$){
      return this.auth.loginWithRedirect()
    }

    if(req.url.includes('/api/')){
      return  this.auth.getAccessTokenSilently().pipe(
        mergeMap(token => {
          if(token){
            const tokenReq = req.clone({

              setHeaders: { Authorization: `Bearer ${token}` }

            });
            localStorage.setItem('access_token', `Bearer ${token}`);

            return next.handle(tokenReq);
          }
          return this.auth.loginWithRedirect();


        }),

        catchError((err: HttpErrorResponse) => {
          console.log('Error:', err);

          if (err.status === 401) {
            // Replace the current page with the HTML error response
            const parser = new DOMParser();
            const doc = parser.parseFromString(err.error, 'text/html');
            document.open();
            document.write(doc.documentElement.outerHTML);
            document.close();

            return EMPTY;
          }

          return throwError(() => err);
        })
      )
    }
    else {
      return next.handle(req);
    }
  }
}
