<ng-container *ngIf="!initialState">
  <div class="modal-header w-100">
    <button type="button" class="btn-reset ml-auto" aria-label="Close" (click)="closeDialog()">
      <i class="icon-WL_icon_close"></i>
    </button>
  </div>
</ng-container>

<div class="modal-body">

  <div class="container-fluid">
    <div class="row">
      <div class="col-lg-6 col-md-12 col-sm-12" style="min-width: 480px">
        <div *ngIf="!isTalentCreation || initialState; else resumeUpload" class="row mt-1 ml-0 upload-titles">
          File upload
        </div>
        <h3 *ngIf="isTalentCreation && !initialState">After upload the document will be parsed.</h3>
        <div class="row mt-5">
          <div class="col-12">
            <div ng2FileDrop (fileOver)="fileOverBase($event)" [uploader]="uploader" [ngClass]="{'nv-file-over': hasBaseDropZoneOver, 'drag-drop-error': talentCreationError}"
                 class="drag-drop justify-content-center">
              <ng-container *ngTemplateOutlet="uploadDefault"></ng-container>
              <div class="row align-items-center justify-content-center mt-2">
                <div class="col-12 text-center">
                  <button (click)="fileInput.click()">upload file(s)</button>
                  <span style="visibility: hidden; position: absolute; overflow: hidden; width: 0px; height:0px;border:none;margin:0; padding:0">
                       <input type="file" #fileInput ng2FileSelect multiple [uploader]="uploader" />
                  </span>
                </div>
              </div>
              <div class="row align-items-center justify-content-center mt-4 text-center">
                <div class="col-12 text-center" style="font-size: 12px; color: #6C6C6C; max-width: 70%;">
                 {{isTalentCreation? 'accepted formats: PDF, DOC, DOCX, PPT, EXCEL, PNG and JPEG' : 'accepted formats: PDF, DOC, DOCX, PPT, EXCEL, PNG and JPEG'}} <!-- 'accepted formats: Word, PDF, Power Point, Excel, PNG and JPEG' -->
                </div>
              </div>
            </div>
            <div *ngIf="isTalentEdit" style="color: #19294F; width: 115%;">
              Note: The resume on file will be updated and
              the skills, work history, and education will be updated.
            </div>
          </div>
        </div>
        <div [ngStyle]="{'margin-left': isTalentCreation ? '4%' : 'none'}" class="row align-items-center" *ngIf="unsupportedItems.length">
          <div *ngIf="!isTalentCreation; else alertCircle" class="col-1">
            <img width="30" src="assets/icons/alert-red.svg" alt="alert" />
          </div>
          <div class="col-11 errorMessage">
            {{unsupportedMessage}}
          </div>
        </div>
        <div class="row align-items-center" *ngIf="duplicateItems.length">
          <div class="col-1">
            <img width="30" src="assets/icons/alert-red.svg" alt="alert" />
          </div>
          <div class="col-11 errorMessage">
            {{duplicateMessage}}
          </div>
        </div>
      </div>

      <div class="col-lg-6 col-md-12 col-sm-12 mt-md-4 mt-sm-4" style="min-width: 480px; padding-left:2rem">
        <div class="row mt-1 upload-titles ">
          <div class="col-3 ml-1">
            Uploading
          </div>
        </div>
        <ng-container *ngIf="uploader?.queue?.length">
          <div class="row mt-4" *ngFor="let item of uploader.queue">
            <div class="icon-container">
              <div class="form-check-container" *ngIf="initialState">
                <input class="form-check-input" type="checkbox" [checked]="isSubmitToAMChecked(item)" />
                <span class="checkmark" (click)="handleWillSubmit(item)"></span>
              </div>
              <div>
                <img width="30" src="assets/icons/saved-beanstalk.svg" alt="saved" *ngIf="item?.isSuccess" />
                <img width="30" src="assets/icons/alert-red.svg" alt="saved" *ngIf="item?.isError || item?.isCancel" />
                <div style="width: 24px; height: 24px" *ngIf="item?.isUploading" class="custom-spinner spinner-border text-primary" role="status">
                  <span class="sr-only text-center">{{'job-details-pane.loading' | translate}}</span>
                </div>
              </div>
            </div>
            <div class="col-5 m-0 p-0">
              <div class="row">
                <div class="col file-name overflow-ellipsis" tooltip="{{item?.file?.name}}" [ngStyle]="{'color': item?.isError ? '#E74536': '#0081FF'}">
                  {{item?.file?.name}}
                </div>
              </div>
              <div class="row mt-2">
                <div class="col">
                  <div class="progress progress-file" style="margin-bottom: 0;">
                    <div class="progress-bar progress-file progress-color" role="progressbar" [ngStyle]="{ 'width': item.progress + '%' }"></div>
                  </div>
                </div>
              </div>
                  <div *ngIf="item?.isError && fileType == 'doc'" class="row mt-2" style="font-size: 11px; color: #E74536;">
                  <div class="col" >
                    Please convert the file to PDF and try again.
                  </div>
                  <!-- <div class="col-4 ml-0 pl-0 pull-right pointer" style="color: #0075E0;" (click)="item?.upload()">
                    Try again
                  </div> -->
                  </div>
                
                <div *ngIf="(item?.isError || item?.isCancel)  && fileType !== 'doc'" class="row mt-2" style="font-size: 14px; color: #E74536;">
                <div class="col" *ngIf="!item?.isCancel">
                  Server error.
                </div>
                <div class="col" *ngIf="item?.isCancel">
                  Canceled by client.
                </div>
                <div class="col-4 ml-0 pl-0 pull-right pointer" style="color: #0075E0;" (click)="item?.upload()">
                  Try again
                </div>
              </div>
            </div>
            <div class="col-4 pl-2 p-0 m-0">
            </div>
            <div class="col-1 ml-2 p-0 m-0">
              <img width="30" class="pointer" *ngIf="!item?.isUploading" src="assets/icons/trash-gray.svg" alt="delete" (click)="item?.remove(); onRemoveItem(item)" />
              <img width="30" class="pointer" *ngIf="item?.isUploading" src="assets/icons/close-gray.svg" alt="cancel" (click)="item?.cancel()" />
            </div>
            <div class="row" style="width: 100%; margin-top: 5px;" [ngClass]="{'margin-left': initialState}">
              <ng-container *ngIf="initialState">
                <div class="form-check-container">
                  <input class="form-check-input" type="checkbox" [checked]="isSaveToProfileChecked(item)" />
                  <span class="checkmark" (click)="handleWillSaveToProfile(item)"></span>
                </div>
                <span>On submit the file will be added to profile</span>
              </ng-container>
            </div>
            <div class="row" style="width: 45%; margin-left: 20px; margin-top: 5px;">
              <label for="attachment-type-dropdown"></label>
              <select #categorySelect id="attachment-type-dropdown" (change)="categorySelectChange(item, categorySelect.value)" class="custom-select" style="height: 40px; border-radius: 4px;">
                <option [ngValue]="''">Select a file type</option>
                <option *ngFor="let category of categories" [value]="category.name">
                  {{category.name}}
                </option>
              </select>
              <div class="text-danger" style="font-size: 12px" *ngIf="!isValid && isSelectedCategoryNull(categorySelect.value)">Please select a file type</div>
            </div>
            <div class="row" style="width: 87%; margin-left: 20px; margin-top: 5px;">
              <input (ngModelChange)="addOptionalDescrip(item, $event)" [ngModel]="optionalDescrip" maxlength="140" placeholder="Add optional description" class="form-control" type="text">
            </div>
          </div>
        </ng-container>
        <ng-container *ngIf="!uploader?.queue?.length">
          <div class="row mt-4 align-items-center">
            <div class="col ml-1">
              There are no files uploading at the moment.
            </div>
          </div>
        </ng-container>
      </div>

    </div>
  </div>
</div>

<div class="modal-footer w-100" *ngIf="!initialState">
  <button class="btn rds--button--primary ml-auto" (click)="closeDialog()" >cancel</button>
  <button class="btn btn-primary" [disabled]="!validFiles().length || uploader.isUploading || !isValid" (click)="addFiles(); closeDialog();" >add {{validFiles().length || ''}} file(s)</button>
</div>

<ng-template #uploadDefault>
  <div class="row align-items-center justify-content-center mt-5">
    <div class="col-12 text-center">
      <img width="30" src="assets/icons/upload_black.svg" alt="upload" />
    </div>
  </div>
  <div class="row align-items-center justify-content-center mt-4">
    <div class="col-12 text-center" style="font-size: 18px; color: #0F1941;">
      drag your file here
    </div>
  </div>
  <div class="row align-items-center justify-content-center mt-2 text-center">
    <div class="col-12 text-center" style="font-size: 14px; color: #0C1944;">
      or
    </div>
  </div>
</ng-template>

<ng-template #uploadError>
  <div class="row align-items-center justify-content-center mt-5">
    <div class="col-12 text-center">
      <img width="30" src="assets/icons/alert-circle-red.svg" alt="upload" />
    </div>
  </div>
  <div class="row align-items-center justify-content-center mt-4">
    <div class="col-12 text-center" style="font-size: 18px; color: #D73B3B;">
      this file format is not supported, please try again
    </div>
  </div>
  <div class="row align-items-center justify-content-center mt-2 text-center">
    <div class="col-12 text-center" style="font-size: 14px; color: #D73B3B;">
      file: {{failedItem}}
    </div>
  </div>
</ng-template>

<ng-template #resumeUpload>
  <div class="row mt-1 ml-0 upload-titles">
    Add resume
  </div>
</ng-template>

<ng-template #alertCircle>
  <div class="col-1">
    <img style="height: 24px;" width="30" src="assets/icons/alert-circle-red.svg" alt="upload" />
  </div>
</ng-template>

